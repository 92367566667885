@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.parallelogram {
  --p: 50px; /* control the shape (can be percentage) */
  height: 100px;
  aspect-ratio: 3/2;
  clip-path: polygon(var(--p) 0, 100% 0, calc(100% - var(--p)) 100%, 0 100%);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: start;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

/* flag label styling */

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-weight: bold;
  color: rgba(254, 0, 148, 1);
}
.ribbon {
  --r: 0.8em; /* control the cutout */

  border-block: 0.5em solid #0000;
  padding-inline: 1em calc(var(--r) + 1em);
  line-height: 1.8;
  clip-path: polygon(
    100% 0,
    0 0,
    0 100%,
    100% 100%,
    100% calc(100% - 0.25em),
    calc(100% - var(--r)) 50%,
    100% 0.25em
  );
  background: radial-gradient(0.2em 50% at left, #000a, #0000) border-box,
    rgba(255, 236, 44, 1) padding-box; /* the color  */
  max-width: 60%;
}

/* -------- Offer Card -------- */

.card {
  width: fit-content;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  width: 30%;
}

.couponCard {
  /* width: fit-content; */
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  width: 90%;
  margin: auto;
}

.cardTwo {
  border-radius: 5px;
  background-color: #fff;
  position: relative;
}

.main,
.copy-button {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
}
.card::after {
  position: absolute;
  content: "";
  height: 40px;
  right: 45%;
  border-radius: 40px;
  z-index: 1;
  top: -20px;
  background-color: #fafafa;
  width: 40px;
}
.cardTwo::after {
  position: absolute;
  content: "";
  height: 30px;
  right: 45%;
  border-radius: 40px;
  z-index: 1;
  top: -20px;
  background-color: #f3f3f3;
  width: 30px;
}

.couponCard::after {
  position: absolute;
  content: "";
  height: 40px;
  right: 45%;
  border-radius: 40px;
  z-index: 1;
  top: -20px;
  background-color: rgba(137, 105, 120, 1);
  width: 40px;
}

.couponCard::before {
  position: absolute;
  content: "";
  height: 40px;
  left: 45%;
  border-radius: 40px;
  z-index: 1;
  bottom: -20px;
  background-color: rgba(137, 105, 120, 1);
  width: 40px;
}

.card::before {
  position: absolute;
  content: "";
  height: 40px;
  left: 45%;
  border-radius: 40px;
  z-index: 1;
  bottom: -20px;
  background-color: #fafafa;
  width: 40px;
}
.cardTwo::before {
  position: absolute;
  content: "";
  height: 30px;
  left: 45%;
  border-radius: 40px;
  z-index: 1;
  bottom: -20px;
  background-color: #f3f3f3;
  width: 30px;
}

.co-img img {
  width: 100px;
  height: 100px;
}
.vertical {
  border-left: 5px dotted black;
  height: 100px;
  position: absolute;
  left: 40%;
}

.content h1 {
  font-size: 35px;
  margin-left: -20px;
  color: #565656;
}

.content h1 span {
  font-size: 18px;
}
.content h2 {
  font-size: 18px;
  margin-left: -20px;
  color: #565656;
  text-transform: uppercase;
}

.content p {
  font-size: 16px;
  color: #696969;
  margin-left: -20px;
}

.copy-button {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  padding: 0 5px;
  border: 1px solid #e1e1e1;
}

.copy-button input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
}

.copy-button button {
  padding: 5px 20px;
  background-color: #fafafa;
  color: #fff;
  border: 1px solid transparent;
}

.buy {
  position: absolute;
  content: "";
  bottom: 20px;
  left: 20px;
  background-color: #dc143c;
}

/* Custom scrollbar styles for the entire website */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(240, 18, 114, 1);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  scroll-behavior: smooth;
}

.inputClassCountry {
  border: 1px solid #d1d5db; /* gray-300 color */
  border-radius: 10px;
  padding: 26px; /* px-4 and py-4 in Tailwind */
  width: 100% !important;
  outline: none;
  border: none;
  background: red;
}
.inputClassCountry.error {
  border: 1px solid red;
}
.inputClassCountry:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flag-dropdown {
  background-color: transparent !important;
  border: 0px !important;
}
.flag-dropdown .selected-flag {
  padding: 0px !important;
  width: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flag-dropdown .selected-flag:hover {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}
.react-tel-input.form-control {
  background: "red";
}


.text-clamp {
 
 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Safari-specific */
  line-clamp: 1; /* Fallback for modern browsers */
}