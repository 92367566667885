.loader {
    position: relative;
    width: 100px;
    height: 16px;
  }
  .loader:before , .loader:after{
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 32px 0 #fff;
    left: 0;
    top: 0;
    animation: ballMoveX 2s linear infinite;
  }
  .loader:after {
    box-shadow: none;
    transform: translateX(64px) scale(1);
    z-index: 100;
    animation: none;
    animation: trfLoader 2s linear infinite;
  }
  
  @keyframes trfLoader {
    0% , 5%{
      transform: translateX(64px) scale(1);
      background: #FFF;
    }
    10%{
      transform: translateX(64px) scale(1);
      background:rgba(255, 48, 147, 1) ;
    }
    40%{
        transform: translateX(32px) scale(1.5);
        background: rgba(255, 48, 147, 1);
      }
    90%, 95% {
      transform: translateX(0px) scale(1);
      background: rgba(255, 48, 147, 1);
    }
    100% {
      transform: translateX(0px) scale(1);
      background: #FFF;
    }
  }
  @keyframes ballMoveX {
    0% , 10%{  transform: translateX(0) }
    90%, 100% { transform: translateX(32px) }
  }
        