/* Slider pagination customizations */
.swiper-pagination-bullet {
    background-color: #f7cdd2; /* Default bullet color */
    opacity: 0.7;
    transition: all 0.3s ease;
  }
  
  .swiper-pagination-bullet:hover {
    opacity: 1; /* Brighten on hover */
  }
  
  .swiper-pagination-bullet-active {
    background-color: rgba(255,48,147,1); /* Active bullet color */
    opacity: 1; /* Fully opaque */
  }
  